(function (factory) {
  if (typeof define === 'function' && define.amd) {
    define(['jquery'], factory);
  } else if (typeof exports === 'object') {
    var jQuery = require('jquery');
    module.exports = factory(jQuery);
  } else {
    factory(window.jQuery || window.$);
  }
}(function ($) {
  "use strict";

  $.fn.serializeJSON = function () {
    var $form, formAsArray, serializedObject, name, value;
    $form = this;

    formAsArray = $form.serializeArray();

    serializedObject = {};
    $.each(formAsArray, function (i, obj) {
      name  = obj.name;
      value = obj.value;
      serializedObject[name] = value;
    });
    return serializedObject;
  };
}));
